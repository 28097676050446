<template>
  <section>
    <v-row class="mt-4">
      <v-col cols="12" md="6">
        <v-text-field
          v-model="keyword"
          solo
          flat
          :label="$t('searchKeywordPlaceholder')"
          append-icon="mdi-magnify"
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-if="statusFilterAvailable"
          v-model="statusFilter"
          :items="statusOptions"
          multiple
          chips
          solo
          flat
          hide-details
          clearable
          deletable-chips
          :label="$t('feedbacks.filterByStatus')"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-simple-table class="mt-8">
      <thead>
        <tr>
          <th v-for="col in headers" :key="`table_header_${col.index}`">
            {{ col.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, rowIndex) in filteredRows"
          :key="`table_body_${rowIndex}`"
        >
          <td
            v-for="col in headers"
            :key="`table_row_${rowIndex}_col_${col.index}`"
          >
            <template v-if="col.type === 'Status' && $route.params.processType">
              {{
                localizeStatus(
                  $route.params.processType,
                  item.columns[col.index]
                )
              }}
            </template>
            <template v-else-if="col.type === 'Status' && isPdpReport">
              {{ $t(`dashboard.status.2.${item.columns[col.index]}`) }}
            </template>
            <template v-else-if="col.type === 'DateTime'">
              <template v-if="item.columns[col.index]">
                {{ $d(new Date(item.columns[col.index]), 'datetime') }}
              </template>
            </template>
            <template v-else>
              <span
                v-html="
                  $options.filters.highlight(item.columns[col.index], keyword)
                "
              ></span>
            </template>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    headers: {
      type: Array,
      required: true
    },

    reportRows: {
      type: Array,
      required: true
    },

    isPdpReport: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    keyword: '',
    statusFilter: []
  }),

  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    }),

    filteredRows() {
      const kw = this.keyword.toLowerCase();

      if (kw === '' && !this.statusFilter) {
        return this.reportRows;
      }

      let rows = [...this.reportRows];

      let statusIndex = -1;
      if (this.statusFilterAvailable) {
        statusIndex = this.headers.filter((x) => x.type === 'Status')[0].index;
      }

      rows = rows.filter((x) => {
        if (
          this.statusFilterAvailable &&
          this.statusFilter &&
          this.statusFilter.length > 0 &&
          !this.statusFilter.includes(x.columns[statusIndex])
        ) {
          return false;
        }

        for (let i = 0; i < this.headers.length; i++) {
          const dataType = this.headers[i].type;
          if (dataType !== 'String') {
            continue;
          }

          const headerIndex = this.headers[i].index;
          const colVal = x.columns[headerIndex]?.toLowerCase();
          if (colVal && colVal.indexOf(kw) > -1) {
            return true;
          }
        }

        return false;
      });

      return rows;
    },

    statusFilterAvailable() {
      if (this.headers === null) {
        return false;
      }

      const statusColumn = this.headers.filter((x) => x.type === 'Status');
      return statusColumn.length > 0;
    },

    statusOptions() {
      if (this.isPdpReport) {
        return this.pdpStatusOptions;
      }

      if (!this.$route.params.processType) {
        return [];
      }

      return this.workflowStatusOptions;
    },

    pdpStatusOptions() {
      return [
        { value: 0, text: this.$t('dashboard.status.2.0') },
        { value: 110, text: this.$t('dashboard.status.2.110') },
        { value: 120, text: this.$t('dashboard.status.2.120') },
        { value: 199, text: this.$t('dashboard.status.2.199') }
      ];
    },

    workflowStatusOptions() {
      const vm = this;
      const options = [];
      const addedStatusIds = [];

      const headerIndex = this.headers.filter((x) => x.type === 'Status')[0]
        .index;

      for (let i = 0; i < this.reportRows.length; i++) {
        const statusId = this.reportRows[i].columns[headerIndex];
        if (addedStatusIds.indexOf(statusId) < 0) {
          addedStatusIds.push(statusId);

          options.push({
            value: statusId,
            text: vm.localizeStatus(vm.$route.params.processType, statusId)
          });
        }
      }

      return options.sort((a, b) => a.value - b.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-data-table tr:hover {
  background-color: transparent !important;
}
</style>
