<template>
  <div>
    <v-autocomplete
      v-model="targetUser"
      :items="userOptions"
      :disabled="disabled"
      label="User auswählen"
      clearable
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    targetUser: null
  }),

  computed: {
    ...mapGetters('users', {
      users: 'getOverviewUsers'
    }),

    userOptions() {
      return this.users
        .map((x) => {
          return {
            text: `${x.firstname} ${x.lastname}`,
            value: x.id
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text));
    }
  },

  watch: {
    targetUser() {
      this.$emit('userSelected', this.targetUser);
    }
  },

  mounted() {
    this.fetchUsers();
  },

  methods: {
    ...mapActions('users', {
      fetchUsers: 'fetchUsers'
    })
  }
};
</script>
